.feed {
  flex: 5.5;
  height: calc(100vh - 50px);
  overflow-y: scroll;
}
.statusProvider {
  display: flex;
  width: 100%;
  gap: 5px;

  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 10px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
