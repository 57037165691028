.messenger{
    height:100%;
    display:flex;
}
.chatMenu{
    flex:3.5;
}
.chatMenuInput{
    width:90%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid grey;
}
.chatBox{
    flex:5.5;
}
.chatBoxWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.chatBoxTop{
    height: 70vh;
    overflow-y: scroll;
    padding-right: 10px;
}
.chatBoxBottom{
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chatMessageInput{
    width: 80%;
    height: 90px;
    padding: 10px;
    border-radius: 10px;
}
.chatSubmitButton{
    width: 70px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: teal;
    cursor: pointer;
    color: white;
    
}
.chatOnline{
    flex:3;
    position: sticky;
}

.chatMenuWrapper,.chatBoxWrapper,.chatOnlineWrapper{
    padding:10px;
    height: 100%;
}
.noConversationText{
    position:absolute;
    top:10%;
    font-size: 50px;
    color:lightgrey;
    text-align: center;
    cursor: default;
}
@media only screen and (max-width: 768px) {
    .chatBoxWrapper{
        width: 100%;
    }
    .chatBoxTop{
        height: auto;
    }
  }