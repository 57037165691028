.chatOnlineFriend{
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    margin-top: 20px;
}
.chatOnlineImgContainer{
    position: relative;
    margin-right: 10px;
}
.chatOnlineImg{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid white;

}
.chatOnlineBadge{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(16, 238, 16);
    position: absolute;
    top:3px;
    right:3px;
}