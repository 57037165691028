.status {
  width: 90%;
  height: 200px;
  margin-bottom: 10px;
  overflow-x: scroll;
}
.statusComponent {
  width: 150px;
  height: 200px;

  border-radius: 20px;
  border: 2px solid red;
  margin: 0px 2px;
  position: relative;
}

.statusProfile {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid blue;
  z-index: 5;
}
.back {
  width: 100%;
  height: 100%;
}
.back > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  position: relative;
}
.statusRem {
  display: flex;
  width: 150px;
  height: 200px;
  border: 1px solid red;
}
.statusAddIcon {
  font-size: 40px !important;
  position: absolute;
  top: 40%;
  left: 40%;
}
.statusAdder {
  font-size: 10px;
  position: absolute;
  top: 80%;
  left: 40%;
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 10px;
  padding: 3px;
}
