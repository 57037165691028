.post {
  width: 100%;
  border-radius: 10px;
  box-shadow: -7px 20px 36px -4px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: -7px 20px 36px -4px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: -7px 20px 36px -4px rgba(0, 0, 0, 0.57);
  margin: 30px 0;
  padding: 10px;
}
.postTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.postTopLeft {
  display: flex;
  align-items: center;
}
.postProfileImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.postUserName {
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px;
}
.postDate {
  font-size: 12px;
}
.postCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}
.postText {
  margin-left: 20px;
  font-weight: 500;
}
.postImg {
  margin-top: 20px;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}
.postBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.postBottomLeft {
  display: flex;
  align-items: center;
}
.likeIcon {
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin: 20px 10px;
  cursor: pointer;
}
.heartIcon {
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin: 20px 10px;
  cursor: pointer;
}
.postLikeCounter {
  font-size: 15px;
}
.postCommentText {
  cursor: pointer;
  border-bottom: 1px dashed gray;
  font-size: 15px;
}
.moreVert {
  width: 32px;
  height: 32px;
  object-fit: cover;
  color: white;

  background-color: transparent;
}
.moreVert:hover {
  cursor: pointer;
  border-radius: 50%;
  position: center;
  background-color: rgb(128, 128, 126);
}
.comment {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.commentleft {
  display: flex;
  flex-direction: column;
}
.commentright {
  margin-left: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
@media only screen and (max-width: 768px) {
  .postImg {
    width: 80%;
    height: 80%;
    object-fit: contain;
  }
}
