.rightbar{
    flex:3.5;
}
.rightbarWrapper{
    padding:20px 20px 0 0;
}
.birthdayContainer{
    display:flex;
    align-items: center;
}
.birthdayImg{
    width:40px;
    height: 40px;
    margin-right:15px;
}
.birthdayText{
    font-weight: 300;
    font-size: 15px;

}
.rightbarAdd{
    width:100%;
    border-radius: 10px;
    margin:30px 0;
}
.rightbarTitle{
    margin-bottom: 20px;
}
.rightbarFriendList{
    padding:0;
    margin: 0;
    list-style:none;
}
.rightbarFriend{
    display:flex;
    align-items: center;
    margin-bottom: 15px;
}
.rightbarProfileImgContainer{
    margin-right: 10px;
    position:relative;
}
.rightbarProfileImg{
    width:40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.righrbarOnline{
    width:12px;
    height: 12px;
    border-radius: 50%;
    background-color: limegreen;
    position:absolute;
    top:-2;
    right:0;
    border:2px solid white;
}
.rightbarUsername{
    font-weight: 500;
}
.rightbarTitle{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.rightbarInfo{
    margin-bottom: 30px;
}
.rightbarInfoItem{
    margin-bottom: 10px;
}
.rightbarFollowingImg{
    width:100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
}
.rightbarFollowingName{
    font-weight: 500;
    color:orangered;
}
.rightbarInfoKey{
    font-weight: 500;
    margin-right: 15px;
    color:#555;
}
.rightbarInfoValue{
    font-weight: 300;
    color:rgb(128, 72, 34);
}
.rightbarFollowings{
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
}
.rightbarFollowing{
    display:flex;
    flex-direction: column;
    margin-bottom:20px;
    cursor: pointer;
}
.rightbarFollowButton{
    margin:30px;
    margin-bottom: 10px;
    border:none;
    background-color: #1872f2;
    color:white;
    border-radius: 5px;
    padding:5px 10px;
    display:flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    cursor:pointer;

}
.rightbarFollowButton:focus{
outline: none;
}
@media only screen and (max-width: 768px) {
    .rightbar{
    display: none;

    }
  }