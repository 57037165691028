.shareImgContainer{
    padding: 0 20px 10px 20px;
    position: relative;
  }
  
  .shareImg{
    width: 100%;
    object-fit: cover;
  }
  
  .shareCancelImg{
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;
    opacity: 0.7;
  }