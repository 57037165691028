.topbar-container{
    height:50px;
    width:100%;
    background-color: #1877f2;
    display:flex;
    align-items:center;
    position:sticky;
    top:0;
    z-index: 999;
}
.topbarLeft{
    flex:3;
}
.topbarCenter{
    flex:5;
}
.searchbar{
    width:100%;
    height:30px;
    background-color: white;
    border-radius: 30px;
    display:flex;
    align-items: center;
}
.searchIcon{
    font-size: 20px!important;
    margin-left:10px;
}
.searchInput{
    border:none;
    width:100%;
}
.searchInput:focus{
    outline:none;
}
.topbarRight{
flex:4;
display:flex;
align-items:center;
justify-content: space-around;
color:white;
}
.topbarLinks{
margin-right: 10px;
font-size: 14px;
cursor:pointer;
list-style: none;
color:white;
text-decoration: "none";

}
.topbarLinks:link { text-decoration: none;color:white; }
.topbarLinks:visited { text-decoration: none;color:white; }
.topbarLinks:hover { text-decoration: none;color:white; }
.topbarLinks:active { text-decoration: none;color:white; }
.topbarLink{
    margin:0 10px;
}
.topbarIcons{
    display:flex;
}
.topbarIconItem{
    margin-right:15px;
    cursor: pointer;
    position:relative;
    color:white;

}
.topbarIconItem :active{
    text-decoration: none;

}
.topbarIconBadge{
    width:15px;
    height:15px;
    background-color: red;
    border-radius: 50%;
    color:white;
    position:absolute;
    top:-5px;
    right:-5px;
    display:flex;
    align-items:center;
    justify-content: center;
}
.topbarImg{
    width:32px;
    height:32px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.logo{
    font-size: 24px;
    margin-left: 20px;
    font-weight: bold;
    color:white;
    cursor:pointer;
}
.chatIcon:focus{
    text-decoration: none;
    list-style: none;
}
.notifications {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    color: black;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 400px;
  }
  
  .notification {
    padding: 5px;
    font-size: 14px;
  }
  
  .nButton {
    width: 80%;
    padding: 5px;
    margin-top: 10px;
  }
  @media only screen and (max-width: 768px) {
    .topbarLinks{
    display: none;
    }
    .topbarCenter{
        display: none;
    }
  }